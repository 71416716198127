<template>
  <div
    class="tab-pane fade"
    id="pills-accountMappingProduct"
    role="tabpanel"
    aria-labelledby="pills-accountMappingProduct-tab"
  >
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-5">
        <div class="form__item">
          <!-- <label class="form__label">Select a product for mapping accounts</label> -->
          <label class="form__label">Select a product</label>
          <multiselect
            v-model="product"
            id="productName"
            label="productName"
            track-by="productName"
            placeholder="Type to search product"
            open-direction="bottom"
            :options="allProducts"
            :multiple="false"
            :searchable="true"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :max-height="800"
            :show-no-results="false"
            :hide-selected="false"
            @input="filterByProduct"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag">
                <span>{{ option.productName }}</span>
                <span class="custom__remove" @click="remove(option)">❌</span>
              </span>
            </template>
          </multiselect>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end pt-5">
        <button
          class="btn download-box"
          style="background: #000000; color: #ffffff"
          @click="showDataset"
        >
          Configure Account
        </button>
      </div>
    </div>

    <div class="ma-table">
      <div class="ma-thead">
        <div
          v-for="(text, i) in thead_texts"
          :key="i"
          :style="
            i == 0
              ? 'width: 30%;'
              : '' || i == thead_texts.length - 1
              ? 'width: 15%;'
              : ''
          "
          class="thead-child"
        >
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5" />
      <div v-if="mappedProducts.length > 0">
        <div v-for="(item, i) in mappedProducts" :key="i">
          <div class="ma-tbody">
            <div style="width: 30%; word-break: break-word" class="tbody-child">
              {{ item.productName }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{ getProductType(item.productId) }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              <ul v-for="(acc, i) in item.dataSetId" :key="i">
                <li>{{ getAllDatasetForProduct(acc) }}</li>
                <hr
                  v-show="i !== item.dataSetId.length - 1"
                  style="border: 0.4px solid #e5e5e5"
                />
              </ul>
            </div>
            <div class="tbody-child dropdown" style="width: 15%">
              <button
                class="btn dropdown-toggle action"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#editMappingModal"
                  @click="viewDatasetsForAProduct(item.productId)"
                >
                  View Datasets
                </a>
              </div>
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5" />
        </div>
      </div>

      <div v-else-if="loading">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center p5"
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No data available</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  name: "MapAccount",
  props: ["mappedProducts", "loading", "mapDatasets", "allProducts"],
  data() {
    return {
      thead_texts: ["Product Name", "Product Type", "Datasets Name", "Action"],
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      product: null,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getProductType(key) {
      let type = "";

      if (this.allProducts.length) {
        const product = this.allProducts.find(
          (y) => y.id === key || y.loanProductKey === key
        );

        if (product) {
          type = product.id ? "Deposit" : product.loanProductKey ? "Loan" : "";
        }
      }

      return type;
    },
    getAllDatasetForProduct(key) {
      let datasetName = "";

      const set = this.mapDatasets.find((x) => x.id === key);
      datasetName = set.name;

      return datasetName;
    },
    filterByProduct(value) {
      let key = value ? value.loanProductKey || value.id : "";
      let type = value?.id ? "Deposit" : value?.loanProductKey ? "Loan" : "";
      this.$emit("configuredProducts", key, type);
    },
    showDataset() {
      if (this.product) {
        let prod = this.product.id || this.product.loanProductKey;
        this.$emit("showMappedDataset", prod);
      } else {
        this.alertWarning("You need to select a product to configure.");
      }
    },
    viewDatasetsForAProduct(key) {
      this.$emit("showMappedDataset", key);
    },
    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.parameters.pageindex++;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages === "previous") {
        this.parameters.pageindex--;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages !== null) {
        this.parameters.pageindex = pages;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      }
    },
    changeLength(value) {
      this.parameters.pagesize = value;
      this.$emit("getTransData", this.parameters);
    },

    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  computed: {
    setLoading() {
      return this.loading;
    },
  },
};
</script>

<style scoped>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 75%;
  /* border: 1px dashed #c4c4c4; */
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.hid-box {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 23%;
  text-align: flex-start;
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 23%;
  text-align: flex-start;
  padding: 10px 40px 10px 0;
}
.ma-thead,
.ma-tbody {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.ma-table {
  display: flex;
  flex-direction: column;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
input[type="file"] {
  display: none;
}
</style>
