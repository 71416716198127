var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade",attrs:{"id":"pills-accountMappingProduct","role":"tabpanel","aria-labelledby":"pills-accountMappingProduct-tab"}},[_c('div',{staticClass:"toaster show"},[(_vm.alert.show)?_c('div',{staticClass:"toaster__container",class:{
        'toaster--success': _vm.alert.status === 'success',
        'toaster--error': _vm.alert.status === 'fail',
        'toaster--warn': _vm.alert.status === 'warn',
      }},[_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"fa fa-times",on:{"click":function($event){_vm.alert.show = false}}})]),_c('div',[_c('span',{staticClass:"d-block text--small text--600 text--white"},[_vm._v(_vm._s(_vm.alert.title))]),_c('span',{staticClass:"d-block text--tiny text--400 text--white"},[_vm._v(_vm._s(_vm.alert.description))])])]):_vm._e()]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"form__item"},[_c('label',{staticClass:"form__label"},[_vm._v("Select a product")]),_c('multiselect',{attrs:{"id":"productName","label":"productName","track-by":"productName","placeholder":"Type to search product","open-direction":"bottom","options":_vm.allProducts,"multiple":false,"searchable":true,"internal-search":true,"clear-on-select":false,"close-on-select":true,"options-limit":300,"limit":3,"max-height":800,"show-no-results":false,"hide-selected":false},on:{"input":_vm.filterByProduct},scopedSlots:_vm._u([{key:"tag",fn:function({ option, remove }){return [_c('span',{staticClass:"custom__tag"},[_c('span',[_vm._v(_vm._s(option.productName))]),_c('span',{staticClass:"custom__remove",on:{"click":function($event){return remove(option)}}},[_vm._v("❌")])])]}}]),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1)]),_c('div',{staticClass:"col-6 d-flex justify-content-end pt-5"},[_c('button',{staticClass:"btn download-box",staticStyle:{"background":"#000000","color":"#ffffff"},on:{"click":_vm.showDataset}},[_vm._v(" Configure Account ")])])]),_c('div',{staticClass:"ma-table"},[_c('div',{staticClass:"ma-thead"},_vm._l((_vm.thead_texts),function(text,i){return _c('div',{key:i,staticClass:"thead-child",style:(i == 0
            ? 'width: 30%;'
            : '' || i == _vm.thead_texts.length - 1
            ? 'width: 15%;'
            : '')},[_vm._v(" "+_vm._s(text)+" ")])}),0),_c('hr',{staticStyle:{"border":"0.6px solid #e5e5e5"}}),(_vm.mappedProducts.length > 0)?_c('div',_vm._l((_vm.mappedProducts),function(item,i){return _c('div',{key:i},[_c('div',{staticClass:"ma-tbody"},[_c('div',{staticClass:"tbody-child",staticStyle:{"width":"30%","word-break":"break-word"}},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('div',{staticClass:"tbody-child",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.getProductType(item.productId))+" ")]),_c('div',{staticClass:"tbody-child",staticStyle:{"word-break":"break-word"}},_vm._l((item.dataSetId),function(acc,i){return _c('ul',{key:i},[_c('li',[_vm._v(_vm._s(_vm.getAllDatasetForProduct(acc)))]),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(i !== item.dataSetId.length - 1),expression:"i !== item.dataSetId.length - 1"}],staticStyle:{"border":"0.4px solid #e5e5e5"}})])}),0),_c('div',{staticClass:"tbody-child dropdown",staticStyle:{"width":"15%"}},[_c('button',{staticClass:"btn dropdown-toggle action",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Action ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#editMappingModal"},on:{"click":function($event){return _vm.viewDatasetsForAProduct(item.productId)}}},[_vm._v(" View Datasets ")])])])]),_c('hr',{staticStyle:{"border":"0.6px solid #e5e5e5"}})])}),0):(_vm.loading)?_c('div',[_vm._m(0)]):_c('div',[_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-center p5"},[_c('div',{staticClass:"spinner-border",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('span',{staticClass:"text--black text--600 text--medium d-inline-flex ml-4"},[_vm._v("Please wait")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center p5"},[_c('img',{attrs:{"src":require("@/assets/img/empty.svg"),"alt":"delete","srcset":""}}),_c('span',{staticClass:"d-block text--danger text--600 text--medium mt-3 mb-5"},[_vm._v(" No data available")])])
}]

export { render, staticRenderFns }